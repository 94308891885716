<template>
  <Card class="mt-md-15" style="margin-bottom: 200px">
    <v-card-title> Buscar por patente </v-card-title>
    <v-card-subtitle>
      Encuentra los datos de tu último service con la patente de tu vehículo
    </v-card-subtitle>

    <v-card-text>
      <div class="mx-auto">
        <v-text-field
          v-model="patent"
          solo
          label="Patente"
          prepend-inner-icon="mdi-car"
          append-icon="mdi-send"
          @click:append="search"
          v-on:keyup.enter="search"
        ></v-text-field>
      </div>
    </v-card-text>
  </Card>
</template>

<script>
import Card from "@/components/card.vue";
export default {
  name: "Home",
  components: {
    Card,
  },
  data: () => ({
    patent: "",
  }),
  created() {
    document.title = "Eduardo Ramos | Lubricentro";
  },
  methods: {
    search() {
      if (this.patent.trim() != "")
        this.$router.push("/cliente/" + this.patent.trim().toUpperCase());
    },
  },
};
</script>