import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import { auth } from '@/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/cliente/:patent',
    name: 'Client',
    component: () => import(/* webpackChunkName: "client" */ '@/views/client.vue')
  },
  {
    path: '/admin',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin.vue'),
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "home admin" */ '@/components/table.vue'),
      },
      {
        path: 'nuevo',
        name: 'New Service',
        component: () => import(/* webpackChunkName: "new service" */ '@/components/service.vue'),
      },
      {
        path: 'editar/:id',
        name: 'Edit Service',
        component: () => import(/* webpackChunkName: "edit service" */ '@/components/service.vue'),
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    beforeEnter: (to, from, next) => {
      if (!!auth.currentUser) {
        next('/admin')
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
