import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBE38BtzdPR35cVLDzPxUEC6EqqAAhvysA",
    authDomain: "eduardo-ramos-lubricentro.firebaseapp.com",
    projectId: "eduardo-ramos-lubricentro",
    storageBucket: "eduardo-ramos-lubricentro.appspot.com",
    messagingSenderId: "675000497036",
    appId: "1:675000497036:web:ba33a1e7e04e3504071ca9",
    measurementId: "G-X5WLRJGQP9"
}

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

const servicesCollection = firestore.collection('services');

export {
    auth,
    firestore,
    storage,
    servicesCollection
}