import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { auth, servicesCollection, storage } from '@/firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: {},
    error: '',
    subcriptionServices: null,
    services: [],
    loadingServices: false,
    selectedService: null,
    loadingService: false,
    uploadingService: false,
    clientService: null,
    searching: false,
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setError(state, val) {
      state.error = val;
      if (val != '' && state.error != '')
        setTimeout(() => state.error = '', 5000);
    },
    commitServices(state, val) {
      state.services = val;
    }
  },
  actions: {
    async searchByPatent({ state }, patent) {
      state.searching = true;
      state.clientService = null;
      servicesCollection.where("patent", '==', patent).limit(20).get().then(function (doc) {
        if (doc.docs.length > 0) {
          state.clientService = doc.docs.map(d => {
            return {
              id: d.id,
              ...d.data(),
            }
          }).sort((a, b) => {
            if (a.date < b.date) {
              return 1;
            }
            if (a.date > b.date) {
              return -1;
            }
            return 0;
          })
        } else {
          state.clientService = null;
        }
        state.searching = false;
      }).catch(function (error) {
        state.searching = false;
      })
    },
    async login({ dispatch, commit }, form) {
      try {
        const { user } = await auth.signInWithEmailAndPassword(form.email, form.password);
        dispatch('fetchUserProfile', user)
      } catch (error) {
        console.log(error);
        commit('setError', error.code)
      }
    },
    async logout() {
      await auth.signOut();
      router.push('/login');
    },
    async fetchUserProfile({ commit }, user) {
      // const userProfile = await firestore.usersCollection...
      // commit('setUserProfile', userProfile.data())
      router.push('/admin')
    },
    async setError({ commit }, val) {
      commit('setError', val);
    },
    async getServices({ state, commit }, month) {
      state.loadingServices = true;
      if (state.subcriptionServices != null) {
        state.subcriptionServices();
      }
      commit('commitServices', [])
      state.subcriptionServices = await servicesCollection.where("month", '==', month).onSnapshot(function (doc) {
        if (state.loadingServices) state.loadingServices = false;
        const services = doc.docs.map((d) => {
          let data = d.data()
          data.id = d.id;
          return data;
        });
        commit('commitServices', services)
      }, function (error) {
        if (state.loadingServices) state.loadingServices = false;
      });
    },
    async deleteService({ }, id) {
      try {
        await servicesCollection.doc(id).delete()
        await storage.ref().child('pdfs').child(id + '.pdf').delete();
      } catch (error) {
        console.log(error)
      }
    },
    async selectService({ state }, id) {
      state.loadingService = true;
      servicesCollection.doc(id).get().then(function (doc) {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          state.selectedService = data;
        } else {
          state.selectedService = null;
          // TODO: navegar a administracion y mostrar mensaje de advertencia
        }
        state.loadingService = false;
      }).catch(function (error) {

        state.loadingService = true;
      })
    },
    async uploadPdf({ state }, data) {
      const ref = storage.ref().child('pdfs').child(data.id + '.pdf');
      ref.put(data.file).then(async function (val) {
        const url = await val.ref.getDownloadURL()
        await servicesCollection.doc(data.id).update({ pdf: url });
        state.uploadingService = false;
        router.push('/admin')
      }).catch(function (err) {
        state.uploadingService = false;
      })
    },
    async updateService({ dispatch, state }, data) {
      state.uploadingService = true;
      try {
        if (!data.id) {
          const ref = await servicesCollection.add(data.service)
          data.id = ref.id;
        } else {
          await servicesCollection.doc(data.id).update(data.service);
        }
        if (data.file != null) {
          dispatch('uploadPdf', data);
        }
        else {
          state.uploadingService = false;
          router.push('/admin')
        }

      } catch (error) {
        console.log(error);
        state.uploadingService = false;
      }
    }
  },
  modules: {
  }
})
